import { Topbar } from '../ui/topbar/Topbar';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PeriodInfo from '../ui/calendar/PeriodInfo';
import './Dashboard.scss';
import { getUserBookedSpaces, getSharedSpaces, getSharedSpace, getBookedSpace, getParkingSpace } from 'utils/ParkingService';
import MomentUtils from '@date-io/moment';
import { resolveMinMaxDates } from 'utils/DateTimeResolver';
import { HIDE_BOOKING } from 'constants/Features';
const moment = new MomentUtils({ locale: 'lt', });

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            clicked: null,
            isloaded: null,
            inlcudeWeekendsInSharing: false
        }
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, true);
        const inlcudeWeekendsInSharing = (this.props.settings.Customer.InlcudeWeekendsInSharing?.value ?? 'false') === 'true'
        this.setState({ inlcudeWeekendsInSharing: inlcudeWeekendsInSharing });
    }

    navigate = async (pathname, state, date) => {
        const hideBooking = this.props.enabledFeatures[HIDE_BOOKING] ?? false;
        switch (pathname) {
            case '/exchanges': {
                if (hideBooking) {
                    break;
                }
                this.setState({ clicked: date });
                this.props.history.push({ pathname: pathname, state: state });
                break;
            }
            case '/subscriptions/new': {
                if (hideBooking) {
                    break;
                }
                this.setState({ clicked: date });
                let userBookings = null;
                if (this.props.currentUser) {
                    userBookings = await getUserBookedSpaces(this.props.currentUser.id, 'active', null, null, true);
                }
                this.props.history.push({
                    pathname: pathname,
                    state: {
                        subscribe: true,
                        user: this.props.currentUser,
                        sharedDates: state.sharedDates,
                        bookedSpaces: userBookings.items,
                        item: {
                            from: date,
                            to: date
                        },
                        subscribeLimit: parseInt(this.props.settings.Customer.SubscriptionPeriodLimit?.value ?? '0')
                    },
                });
                break;
            }
            case '/subscriptions/edit': {
                if (hideBooking) {
                    break;
                }
                this.setState({ clicked: date });
                let periods = state.sharedDates;
                if (this.props.currentUser && this.props.currentUser.id) {
                    const userBookings = await getUserBookedSpaces(this.props.currentUser.id, 'active', null, null, true);
                    periods = userBookings && userBookings.items
                    ? periods.concat(
                        userBookings.items.map((space) => ({
                          from: space.bookedFrom,
                          to: space.bookedTo,
                          id: space.id,
                          userId: space.userId,
                        }))
                      )
                    : periods;
                }
                const dateRange = resolveMinMaxDates(periods, state.item.from, state.item.to, state.item.id);

                this.props.history.push(pathname, {
                    subscribe: true,
                    user: this.props.currentUser,
                    item: state.item,
                    minDate: dateRange.minDate,
                    maxDate: dateRange.maxDate,
                    subscribeLimit: parseInt(this.props.settings.Customer.SubscriptionPeriodLimit?.value ?? '0')
                });
                break;
            }
            case '/bookings/edit': {
                if (hideBooking) {
                    break;
                }
                this.setState({ clicked: date });
                const bookedSpaceId = state.bookedSpaceId;
                if (bookedSpaceId) {
                    const bookedSpace = await getBookedSpace(bookedSpaceId);
                    const bookings = await getUserBookedSpaces(null, null, null, bookedSpace.sharedSpaceId, true);
                    const userBookings = await getUserBookedSpaces(bookedSpace.userId, null, null, null, true);
                    const items = bookings.items.concat(userBookings.items);
                    let space = await getSharedSpace(bookedSpace.sharedSpaceId);
                    let from_ = null;
                    let to_ = null;
                    let sharedDates = null;
                    if (items.length > 0) {
                        sharedDates = items.filter((e) => e.id !== bookedSpace.id).map((e) => {
                            return { from: e.bookedFrom, to: e.bookedTo };
                        });
                    }

                    let minDate = moment.date(new Date()).utc(0).startOf('day');
                    let maxDate = moment.date(new Date(space.sharedTo)).utc(0).endOf('day');
                    if (from_) {
                        let fromDate = moment.date(new Date(from_)).utc(0).add(1, 'days').startOf('day');
                        if (minDate < fromDate) {
                            minDate = fromDate;
                        }
                    } else {
                        let fromDate = moment.date(new Date(space.sharedFrom)).utc(0).startOf('day');
                        if (minDate < fromDate) {
                            minDate = fromDate;
                        }
                    }
                    if (to_) {
                        maxDate = moment.date(new Date(to_)).utc(0).add(-1, 'days').endOf('day');
                    }

                    minDate = minDate.format('YYYY-MM-DDT12:00:00.000').toString();
                    maxDate = maxDate.format('YYYY-MM-DDT12:00:00.000').toString();

                    const parkingSpace = await getParkingSpace(space.parkingSpaceId);

                    this.props.history.push({
                        pathname: '/bookings/edit',
                        state: {
                            space: {
                                name: parkingSpace.name,
                                parkingLotId: space.parkingLotId,
                                // parkingLotName: parkingLotName,
                                parkingSpaceId: space.parkingSpaceId,
                                sharedFrom: space.sharedFrom,
                                sharedTo: space.sharedTo,
                                ownerId: space.ownerId,
                                id: space.id,
                                bookingLevel: parkingSpace.bookingLevel
                            },
                            bookedSpace: bookedSpace,
                            user: {
                                name: '',
                                id: bookedSpace.userId,
                            },
                            minDate_: minDate,
                            maxDate_: maxDate,
                            sharedDates: sharedDates,
                        },
                    });
                }
                break;
            }
            case '/exchanges/new': {
                this.setState({ clicked: date });
                const parkingSpaceId = state.parkingSpaceId;
                const shareFrom = state.shareFrom;
                const spaceNames = state.spaceNames
                if (parkingSpaceId) {
                    let space = await getParkingSpace(parkingSpaceId);
                    const sharedDates = await this.fetchSharedSpacesDates(space.id);
                    const user = this.props.currentUser;
                    space.ownerId = user.id;

                    this.props.history.push({
                        pathname: '/exchanges/new',
                        state: {
                            space: space,
                            sharedDates: sharedDates,
                            user: user,
                            shareFrom: shareFrom,
                            spaceNames: spaceNames
                        },
                    });
                }

                break;
            }
            case '/exchanges/edit': {
                this.setState({ clicked: date });
                const sharedSpaceId = state.sharedSpaceId;
                if (sharedSpaceId) {
                    const bookSpace = await getSharedSpace(sharedSpaceId);
                    const sharedDates_ = await this.fetchSharedSpacesDates(bookSpace.parkingSpaceId);

                    let from_ = sharedDates_.sort((a, b) => (a.to < b.to ? 1 : -1)).find((x) => x.to < bookSpace.sharedFrom)?.to;
                    let to_ = sharedDates_.sort((a, b) => (a.from > b.from ? 1 : -1)).find((x) => x.from > bookSpace.sharedTo)?.from;
                    let minDate = moment.date(new Date()).utc(0).startOf('day');
                    let maxDate = null;
                    if (from_) {
                        let fromDate = moment.date(new Date(from_)).utc(0).add(1, 'days').startOf('day');
                        if (minDate < fromDate) {
                            minDate = fromDate;
                        }
                    }
                    if (to_) {
                        maxDate = moment.date(new Date(to_)).utc(0).add(-1, 'days').endOf('day');
                        maxDate = maxDate.format('YYYY-MM-DDT12:00:00.000').toString();
                    }
                    let sharedDates = [];
                    let disabledForStart = [];
                    let disabledForEnd = [];
                    if (bookSpace.bookings.length > 0) {
                        sharedDates = bookSpace.bookings.map((e) => {
                            return { from: e.bookedFrom, to: e.bookedTo };
                        });
                        disabledForStart = bookSpace.bookings.map((e) => {
                            return {
                                from: moment.date(e.bookedFrom).utc().add(1, 'days').format('YYYY-MM-DDT00:00:00.000').toString(),
                                to: e.bookedTo,
                            };
                        });
                        disabledForEnd = bookSpace.bookings.map((e) => {
                            return {
                                from: e.bookedFrom,
                                to: moment.date(e.bookedTo).utc().add(-1, 'days').format('YYYY-MM-DDT23:59:59.999').toString(),
                            };
                        });
                    }
                    const parkingSpace = await getParkingSpace(bookSpace.parkingSpaceId);

                    minDate = minDate.format('YYYY-MM-DDT12:00:00.000').toString();

                    this.props.history.push({
                        pathname: '/exchanges/edit',
                        state: {
                            space: parkingSpace,
                            minDate_: minDate,
                            maxDate_: maxDate,
                            from_: bookSpace.sharedFrom,
                            to_: bookSpace.sharedTo,
                            id: bookSpace.id,
                            sharedDates: sharedDates,
                            disabledForStart: disabledForStart,
                            disabledForEnd: disabledForEnd,
                        },
                    });

                }
                break;
            }
            default:
                break;
        }
    }

    fetchSharedSpacesDates = async (id) => {
        try {
            const { items } = await getSharedSpaces(id, true);
            return items.map((e) => {
                return {
                    from: e.sharedFrom,
                    to: e.sharedTo
                };
            });
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const { t, enabledFeatures } = this.props;
        const { clicked, inlcudeWeekendsInSharing } = this.state;
        const subscriptionPeriodLimit = this.props.settings.Customer.SubscriptionPeriodLimit;

        return (
            <div>
                <Topbar title={t("dashboard.Title")} ></Topbar>
                <div className="dashboardContainer">
                    <PeriodInfo navigate={this.navigate} clicked={clicked} hideBookingFeatureEnabled={enabledFeatures[HIDE_BOOKING]} inlcudeWeekendsInSharing={inlcudeWeekendsInSharing} subscriptionPeriodLimit={subscriptionPeriodLimit}></PeriodInfo>
                </div>
            </div>
        )

    }
}
export default withTranslation()(Dashboard);
