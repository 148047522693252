import React, { Component } from 'react';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Box, Button, FormControl, Container, TextField, InputLabel, InputAdornment, Grid, IconButton, Typography } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import SquareIcon from '@mui/icons-material/Square';
import './CardForm.scss';
import background from '../../../assets/images/credit-card.png';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

class CardForm extends Component {
    constructor() {
        super();
        this.state = {
            card: {
                cardName: '',
                cardNumber: '',
                expireDate: '',
                cvv: ''
            }
        }
    }

    componentDidMount = async () => {
        const state = this.props.location.state;
        console.log(state);
    }

    goBack = () => {
        this.props.history.goBack();
    };

    handleSubmit = (event) => {
        console.log(this.state.card)
        // NotificationManager.info('development in progress');
        event.preventDefault();
    };

    handleCard = (event) => {
        this.setState(prevState => ({ card: { ...prevState.card, [event.target.id]: event.target.value } }))
    }

    render() {
        const { card } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                {/* <Topbar
                    title='Add New Card'
                    cbBack={this.goBack}
                    location={this.props.location}
                ></Topbar> */}
                         <Box className='box-fixed'>
               <IconButton sx={{mt: 1, ml: 2, color: 'white'}} onClick={this.goBack}>
               <ArrowBackIosNewIcon/>
               </IconButton>
             
                    <Box >
      <Typography variant="h3" sx={{color: 'white', textAlign: 'center'}} gutterBottom>
      
      </Typography>
      </Box>
                </Box>
                <form noValidate onSubmit={this.handleSubmit}>
                    <Container fixed className="card-form-container">
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img src={background}
                                className="CreditCardBackround"
                                alt="background" />

                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <InputLabel shrink htmlFor="cardName" className='inputLabelStyle'>
                                Card Name
                            </InputLabel>
                            <FormControl fullWidth >
                                <TextField
                                    id="cardName"
                                    value={card.cardName}
                                    onChange={this.handleCard}
                                    className="inputShadow"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <InputLabel shrink htmlFor="cardNumber" className="inputLabelStyle">
                                Card Number
                            </InputLabel>
                            <FormControl fullWidth >
                                <TextField
                                    id="cardNumber"
                                    value={card.cardNumber}
                                    onChange={this.handleCard}
                                    className="inputShadow"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "-webkit-inline-box", mt: 2, width: "100%" }}>
                            <Box sx={{ width: "40%" }}>
                                <InputLabel shrink htmlFor="expireDate" className="inputLabelStyle">
                                    Expire Date
                                </InputLabel>
                                <FormControl fullWidth sx={{ pr: 1 }}>
                                    <TextField
                                        id="expireDate"
                                        value={card.expireDate}
                                        onChange={this.handleCard}
                                        className="inputShadow"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ width: "40%" }}>
                                <InputLabel shrink htmlFor="cvv" sx={{ pl: 1 }} className="inputLabelStyle">
                                    CVV
                                </InputLabel>
                                <FormControl fullWidth sx={{ pl: 1 }}>
                                    <TextField
                                        id="cvv"
                                        value={card.cvv}
                                        onChange={this.handleCard}
                                        className="inputShadow"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SquareIcon sx={{ color: "rgba(35, 49, 68, 0.25)" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 4.5 }}>
                            <Button
                                disableElevation
                                fullWidth
                                variant="contained"
                                color="secondary"
                                type="submit"
                                className='buttonStyle'

                            >
                                Add New Card
                            </Button>
                        </Box>
                    </Container>
                </form>
            </React.Fragment>
        )
    }
}

export default withSnackbar(withTranslation()(CardForm));