import { axiosA } from './AuthService';
import MomentUtils from '@date-io/moment'
import { NotificationManager } from 'react-notifications';
import i18next from 'i18next';

const moment = new MomentUtils({ locale: 'lt' })

const putSharedSpaceRequest = async (item, id) => {
	const body = {
		id: id,
		from: item.from,
		to: item.to,
	}
	try {
		const { data } = await axiosA.put(`/SharedSpaceRequest/${id}`, body)
		return data
	}
	catch (e) {
		throw new Error(e)
	}
}

const deleteSharedSpaceRequest = async (id) => {
	try {
		// if (window.confirm(i18next.t('confirm.Delete'))) {
		const { data } = await axiosA.delete(`/SharedSpaceRequest/${id}`);
		return data;
		// }
	} catch (e) {
		return new Error(e);
	}
};

const allSharedSpacesRequests = async (userId, dateFrom, limit) => {
	let query = '?1';
	if (userId) {
		query += `&UserId=${userId}`;
	}

	if (dateFrom) {
		let today = moment.date(dateFrom)
			.utc(0)
			.startOf('day')
			.format('YYYY-MM-DDT00:00:00.000')
			.toString();

		query += `&From=${today}`;
	}

	if (limit) {
		query += `&Limit=${limit}`
	} else {
		query += `&Limit=5`
	}

	const { data } = await axiosA.get(`/SharedSpaceRequest${query}`);
	return data;
};

const requestSharedSpace = async (from, to, userId) => {
	if (!to) {
		NotificationManager.error(i18next.t('error.EndOfPeriodRequired'));
		return;
	}
	const rqs = {
		From: from.format('YYYY-MM-DDT00:00:00.000').toString(),
		To: to.format('YYYY-MM-DDT23:59:59.999').toString(),
	};

	if (userId) {
		rqs['userId'] = userId;
	}

	const data = await axiosA.post('/SharedSpaceRequest', rqs);
	return data;
};

export {
	putSharedSpaceRequest,
	allSharedSpacesRequests,
	requestSharedSpace,
	deleteSharedSpaceRequest
}