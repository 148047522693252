import React, { Component } from 'react';
import './Exchanges.scss';
import { Topbar } from '../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import ExchangesListItem from './list/item/ExchangesListItem';
import { Fab, Dialog, Grid, List, Box, CircularProgress } from "@mui/material";
import {
  deleteShareSpace,
  findSharedSpaces,
  getParkingTerritories,
  getLastTerritory,
  getOwnerParkingSpaces,
  getParkingLots
} from "../../utils/ParkingService";
import { requestSharedSpace } from "../../utils/SharedSpaceRequestService";
import { withSnackbar } from 'notistack';
import InputDate from '../ui/inputs/date/InputDate';
import MomentUtils from "@date-io/moment";
import { InputSelect } from 'components/ui/inputs/select/Select';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../constants/Api';
import InfiniteScroll from "react-infinite-scroll-component";

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@material-ui/styles';

import ListHead from '../ui/list/head/ListHead';

import { AUTO_MATCH, HIDE_BOOKING } from 'constants/Features'

const fetchSharedSpacesDebounced = AwesomeDebouncePromise(findSharedSpaces, Api.SEARCH_DEBOUNCE);

const moment = new MomentUtils({ locale: 'lt' })

class Exchanges extends Component {
  constructor() {
    super();
    this.state = {
      sharedSpaces: [],
      openedSpacesDialog: false,
      VacantFrom: moment.date(),
      VacantTo: null,
      parkingTerritories: [],
      selectedTerritory: null,
      territoryId: null,
      isOwner: true,
      parkingLots: null,
      clicked: false,
      autoMatchFeatureEnabled: false,
      maxVacantTo: moment.date().add(1, 'y'),
      hideSubscriptionButton: false
    };
  }

  materialTheme = createTheme({
    overrides: {
      // https://stackoverflow.com/a/63250102
      MuiFab: {
        root: {
          '&$disabled': {
            color: 'white',
          },
        },
        disabled: {},
      },
    },
  });

  fetchSharedSpaces = async (offset, loadingMore) => {
    let hasMore = false;
    let params = {
      VacantFrom: this.state.VacantFrom,
      VacantTo: this.state.VacantTo,
    };
    if (this.state.selectedTerritory) {
      params["TerritoryId"] = this.state.selectedTerritory.id;
    }

    const resp = await fetchSharedSpacesDebounced(params, offset, -1);
    const items = resp.items ? resp.items : resp;
    if (resp.nextOffset && resp.nextOffset > 0) hasMore = true;

    if (!loadingMore) {
      this.setState((prevState) => ({
        sharedSpaces: [...items],
        nextOffset: resp.nextOffset,
        count: items,
        hasMore,
      }));
    } else {
      this.setState((prevState) => ({
        sharedSpaces: [...new Set([...prevState.sharedSpaces, ...items])],
        nextOffset: resp.nextOffset,
        count: items,
        hasMore,
      }));
    }
  };

  bookSpace = async (space, vacantTo) => {
    const vacant = vacantTo.vacantTo;
    const formvacant = vacant ? vacant.format() : null;
    this.props.history.push({
      pathname: "/bookings/new",
      state: {
        space, user: this.props.currentUser,
        vacantTo: formvacant,
      },
    });
  };

  deleteSharedSpace = async (id) => {
    try {
      const { affectedIds } = await deleteShareSpace(id);
      if (affectedIds && affectedIds.length) {
        await this.setState({
          sharedSpaces: [
            ...this.state.sharedSpaces.filter(
              (e) => !affectedIds.includes(e.id)
            ),
          ],
        });
        this.props.enqueueSnackbar(
          this.props.t("notification.success.DeleteSharedSpace"),
          {
            variant: "success",
          }
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchInfo = async () => {
    await this.fetchSharedSpaces(0);
  };

  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, true);
    if (this.props.settings.Customer.SubscriptionPeriodLimit && this.props.settings.Customer.SubscriptionPeriodLimit.value > 0) {
      const maxVacantTo = moment.date().add(this.props.settings.Customer.SubscriptionPeriodLimit.value, 'd');
      this.setState({ maxVacantTo });
    }
    if (this.props.location.state) {
      const { VacantFrom, territoryId } = this.props.location.state;
      if (VacantFrom, territoryId) {
        const date_ = moment.date(VacantFrom);
        await this.setState({ VacantFrom: date_, territoryId: territoryId });
      }
    }
    const autoMatch = this.props.enabledFeatures[AUTO_MATCH] ?? false;
    this.setState({ autoMatchFeatureEnabled: autoMatch });
    const hideSubscriptionButton = this.props.enabledFeatures[HIDE_BOOKING] ?? false;
    this.setState({hideSubscriptionButton});
    // this.fetchLastTerritory()
    // this.fetchInfo()
    this.fetchParkingTerritories();
    this.fetchParkingLots();

    try {
      const spaces = await getOwnerParkingSpaces(this.props.currentUser.id);
      if (spaces && spaces.length <= 0) {
        this.setState({
          ...this.state,
          isOwner: false,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  fetchLastTerritory = async () => {
    try {
      if (this.state.territoryId) {
        await this.setState({
          selectedTerritory: this.state.parkingTerritories.find((c) => c.id == this.state.territoryId),
        });
        this.fetchInfo();
      }
      else {
        const resp = await getLastTerritory();
        if (resp) {
          await this.setState({
            selectedTerritory: this.state.parkingTerritories.find((c) => c.id == resp),
          });
          this.fetchInfo();
        } else {
          this.fetchInfo();
        }
      }
    } catch (e) {
      console.error(e)
    }
  };

  fetchParkingTerritories = async () => {
    try {
      const resp = await getParkingTerritories();
      this.setState({
        parkingTerritories: [
          { id: "", name: this.props.t("spaces.All") },
          ...resp.items,
        ],
      });
      this.fetchLastTerritory();
    } catch (e) {
      console.error(e);
    }
  };

  fetchParkingLots = async () => {
    try {
      const resp = await getParkingLots();
      this.setState({
        parkingLots: resp.items
      });

    } catch (e) {
      console.error(e);
    }
  };

  setLot = async (selectedTerritory) => {
    await this.setState({ selectedTerritory });
    this.fetchSharedSpaces(0);
  };

  setFromDate = async (momentDate) => {
    await this.setState({ VacantFrom: momentDate, sharedSpaces: [] });
    if (this.state.VacantTo) {
      if (momentDate.isAfter(this.state.VacantTo)) {
        await this.setToDate(momentDate);
      }
    }
    this.fetchSharedSpaces(0);
  };

  setToDate = async (momentDate) => {
    await this.setState({ VacantTo: momentDate, sharedSpaces: [] });
    if (this.state.VacantFrom) {
      if (momentDate.isBefore(this.state.VacantFrom)) {
        await this.setFromDate(momentDate);
      }
    }
    this.fetchSharedSpaces(0);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { VacantFrom, VacantTo } = this.state;
    try {
      const data = await requestSharedSpace(VacantFrom, VacantTo, this.props.currentUser.id);
      if (data) {
        this.props.enqueueSnackbar(
          this.props.t('notification.success.SubscriptionCreated'),
          {
            variant: 'success',
          }
        );
        this.props.history.goBack()
      }

    } catch (e) {
      console.error(e)
    }

  };

  render() {
    const { t, isAdmin } = this.props;
    const {
      sharedSpaces,
      openedSpacesDialog,
      parkingTerritories,
      selectedTerritory,
      VacantFrom,
      VacantTo,
      parkingLots,
      clicked,
      autoMatchFeatureEnabled,
      maxVacantTo,
      hideSubscriptionButton
    } = this.state;
    const currentLng = localStorage.getItem('currentLng');

    return (
      <React.Fragment>
        <Dialog fullScreen open={openedSpacesDialog} onClose={this.handleClose}>
          <h1>Pasirinkti vieta</h1>
        </Dialog>
        <Topbar title={t('exchanges.Title')} location={this.props.location} />
        <div className="ExchangesContainer">
          <form
            className="ExchangesForm"
            onSubmit={this.handleSubmit}
            noValidate
          >
            <Grid container>
              <InputSelect
                options={parkingTerritories}
                label={t('general.ParkingLot')}
                onChange={this.setLot}
                value={selectedTerritory}
                getOptionLabel={(option) => option['name']}
                getOptionValue={(option) => option['id']}
                placeholder={t('spaces.All')}
              />
              <Grid item xs={6}>
                <InputDate
                  value={VacantFrom}
                  label={t('exchanges.Begining')}
                  id="from"
                  onChange={(momentDate) => this.setFromDate(momentDate)}
                  currentLng={currentLng}
                  maxDate={maxVacantTo}
                />
              </Grid>
              <Grid item xs={6}>
                <InputDate
                  value={VacantTo}
                  label={t('exchanges.End')}
                  minDate={VacantFrom}
                  id="to"
                  onChange={(momentDate) => this.setToDate(momentDate)}
                  currentLng={currentLng}
                  maxDate={maxVacantTo}
                />
              </Grid>
              {/* // TODO: add feature toggle from somewhere if exists */}
              {autoMatchFeatureEnabled && !this.state.isOwner && !hideSubscriptionButton && (
                <Grid item xs={12}>
                  {clicked ?
                    <Grid container xs={12} justifyContent="center" alignItems="center" >
                      <CircularProgress size={30} />
                    </Grid>
                    :
                    <ThemeProvider theme={this.materialTheme}>
                      <Fab
                        className={`full-width ${this.state.VacantTo ? 'secondary-btn' : ''
                          }`}
                        variant="extended"
                        type="submit"
                        value="Submit"
                        aria-label="Request"
                        disabled={!this.state.VacantTo}
                      >
                        <Box fontSize={14} textAlign="center" textTransform='none'>
                          {/* // TODO: add translation to database ParkingerUi class */}
                          {t('subscriptions.Request')}
                        </Box>
                      </Fab>
                    </ThemeProvider>
                  }
                </Grid>

              )}
            </Grid>
          </form>

          <ListHead title={t('spaces.Spaces')} />
          <List className="ExchangesList">
            <InfiniteScroll
              dataLength={sharedSpaces.length}
              hasMore={this.state.hasMore}
              next={() => this.fetchSharedSpaces(this.state.nextOffset, true)}
              loader={<p>{t('general.loading')}...</p>}
            >
              {sharedSpaces.length > 0 &&
                sharedSpaces.map((e, i) => (
                  <ExchangesListItem
                    vacantFrom={VacantFrom}
                    vacantTo={VacantTo}
                    key={e.id + e.sharedFrom + e.sharedTo}
                    exchange={e}
                    locked={!isAdmin && e.isReadOnly}
                    // onDelete={isAdmin && this.deleteSharedSpace}
                    onBookSpace={this.bookSpace}
                    isNew={true}
                    parkingLots={parkingLots}
                  />
                ))}
            </InfiniteScroll>
          </List>
        </div>
      </React.Fragment>
    );
  }
}
export default withSnackbar(withTranslation()(Exchanges))