import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { getUserVehicles } from 'utils/VehicleService';
import { getPublicTerrtories, getCurrentLot, getParkingLogById, getParkingLog } from 'utils/PublicLotService';
import { Box, Card, Grid, Typography, Divider, Container, IconButton, ListItem, Dialog, InputAdornment, TextField, Autocomplete, Paper, AppBar, ToggleButtonGroup, Toolbar, Button, CircularProgress } from '@mui/material';
import icon_parking from '../../assets/icons/publicApp/icon_parking.svg';
import icon_eur from '../../assets/icons/publicApp/icon_eur.svg';
import { ReactComponent as SwipeArrowRightIcon } from '../../assets/icons/publicApp/icon_swipe_arrow_right.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/publicApp/icon_chevron_left.svg';
import { ReactComponent as NotFoundSearchIcon } from '../../assets/icons/publicApp/icon_search_not_found.svg';
import { ReactComponent as ShowLocationIcon } from '../../assets/icons/publicApp/button_navigate.svg';
import { ReactComponent as MasterCardLogo } from '../../assets/icons/publicApp/logo_mastercard.svg';
import { ReactComponent as VisaLogo } from '../../assets/icons/publicApp/logo_visa.svg';
import { isNative, requestForGeolocationNative } from 'utils/bridge';
import icon_search_active from '../../assets/icons/publicApp/icon_search_active.svg';
import MomentUtils from '@date-io/moment';
import { motion } from 'framer-motion';
import { PublicFooter } from './PublicFooter';
import PaySlider from 'components/ui/buttons/PaySlider';
import ToggleButton from 'components/ui/buttons/ToggleButton';
import './PublicParking.scss';
import { initiatePayment, kevin_banks } from 'utils/KevinService';
import { lastPayment, postPayment } from 'utils/PaymentsService';
import { NotificationManager } from 'react-notifications';
import BankImg from 'components/public/providers/kevin/BankImg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faLink } from '@fortawesome/free-solid-svg-icons';
import VehiclesCard from './vehicles/VehiclesCard';
import { postVehicle, deleteVehicle as deleteVehicleFn } from 'utils/VehicleService';
import PopUpDrawer from 'ui-component/PopUpDrawer/PopUpDrawer';
import { HubConnectionBuilder } from '@microsoft/signalr'
import { getHubPath } from 'utils/ProxyPathService';
import { CARD, LINKED_BANK, BANK } from 'constants/PaymentTypes';
import calculateDistance from 'utils/geolocationHelper';

const moment = new MomentUtils({ locale: 'en' })

class PublicParking extends Component {
    constructor() {
        super();
        this.state = {
            myLocation: {},
            vehicles: null,
            userId: null,
            lots: [],
            lot: null,
            parkingLog: null,
            mounted: true,
            open: false,
            searchLots: '',
            totalTime: '00h 00min',
            exitDate: null,
            slideValue: 0,
            openSearchOptions: false,
            searchLotsHistory: [],
            maxSearchHistoryLength: 4,
            selectedTypeOfParkings: 'active',
            payment: null,
            vatAmount: 0.00,
            withoutVatAmount: 0.00,
            accountLinked: false,
            linkedBanks: [],
            vehicleForDelete: null,
            selectedPaymentMethod: null,
            vehiclesLimit: 1,
            currentLotSearchFinished: false
        };
        this.getPaymentDataTimer = {};
        this.getPaymentDataSynchronizeTimeout = {};
    }

    componentWillUnmount = () => {
        this.clearPaymentDataTimer();
        this.setState({ mounted: false });
        if (this.state.hubConnection) {
            this.state.hubConnection.stop();
        }
    }

    addLocationNativeListener = () => {
        //requests react-native app
        window.addEventListener("message", (event) => {
            const { type, position } = event.data;
            if (type === 'location') {
                this.setPosition(position);
            }
        });
    }

    setPosition = (position) => {
        const location = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.setState({ myLocation: location });
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, false);
        this.addLocationNativeListener();

        const hubConnection = new HubConnectionBuilder()
            .withUrl(getHubPath()).withAutomaticReconnect()
            .build();

        this.setState({ hubConnection }, () => {
            this.state.hubConnection.start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));

            this.state.hubConnection.on("ReceiveMessage", data => {
                if (data === localStorage.getItem('selectedVehicle')) {
                    this.fetchParkingData();
                }
            });
        });

        const userId = this.props.currentUser ? this.props.currentUser.id : null;
        if (userId) {
            this.setState({ userId: userId });
            if (this.props.settings) {
                this.setState({
                    vehiclesLimit: this.props.settings.Customer.VehiclesLimit?.value ?? 1
                });
            }
        }

        const linkedBanks = JSON.parse(localStorage.getItem('kevinTokens')) ?? [];
        this.setState({ linkedBanks });

        getPublicTerrtories().then((response) => {
            if (this.state.mounted) {
                this.setState({ lots: response });
            }
        })

        await this.fetchData(userId);

        const storedSearchLotsHistory = localStorage.getItem('searchLotsHistory');

        if (storedSearchLotsHistory) {
            this.setState({
                searchLotsHistory: JSON.parse(storedSearchLotsHistory),
            });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.payment && (!prevState.payment || prevState.payment.amount !== this.state.payment.amount)) {
            this.setState({ vatAmount: this.getVat(), withoutVatAmount: this.getWithoutVat() });
        }
    }

    fetchSelectedPaymentMethod = async (userId) => {
        let selectedPaymentMethod = JSON.parse(localStorage.getItem('selectedPaymentMethod'));
        if (!selectedPaymentMethod || selectedPaymentMethod.paymentType === CARD) {
            const plateNum = localStorage.getItem('selectedVehicle')
            lastPayment(userId, plateNum).then((bankId) => {
                if (bankId) {
                    const countryCode = bankId.slice(-2);
                    localStorage.setItem('countryCode', countryCode);
                    let bank = this.state.linkedBanks.find(x => x.id === bankId)
                    if (bank) {
                        selectedPaymentMethod = { 'paymentType': LINKED_BANK, 'bank': bank };
                        localStorage.setItem('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
                        this.setState({ selectedPaymentMethod });
                    } else {
                        kevin_banks().then((allBanks) => {
                            if (allBanks) {
                                bank = allBanks.find(x => x.id === bankId)
                                if (bank) {
                                    selectedPaymentMethod = { 'paymentType': BANK, 'bank': bank };
                                    localStorage.setItem('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
                                    this.setState({ selectedPaymentMethod });
                                }
                            }
                        })
                    }
                }
            })
        } else {
            this.setState({ selectedPaymentMethod });
        }
    }

    fetchVehiclesData = async (userId) => {
        const plateNum = localStorage.getItem('selectedVehicle')
        if (userId) {
            getUserVehicles(userId).then((vehicles) => {
                if (this.state.mounted && vehicles) {
                    let _vehicles = vehicles || [];
                    this.setState({ vehicles: _vehicles });
                    if (vehicles.length > 0) {
                        let plateNum_ = vehicles[0].plateNum;
                        if (plateNum) {
                            const vehicle_ = vehicles.find(x => x.plateNum === plateNum)
                            if (vehicle_) {
                                plateNum_ = plateNum
                            }
                        }
                        localStorage.setItem('selectedVehicle', plateNum_);
                    }
                    else {
                        localStorage.removeItem('selectedVehicle');
                    }
                }
            });
        } else {
            let vehicles = [];
            if (plateNum) {
                vehicles = [{ plateNum: plateNum }]
            }
            this.setState({ vehicles });
        }
    }

    fetchParkingData = async () => {
        this.setState({ currentLotSearchFinished: false });
        await getCurrentLot(localStorage.getItem('selectedVehicle')).then((response) => {
            if (this.state.mounted) {
                if (response) {
                    this.setState({ lot: response.lot })
                    this.setState({ parkingLog: response.parkingLog, exitDate: response.parkingLog.exitDate });
                } else {
                    if ("geolocation" in navigator) {
                        if (isNative()) {
                            requestForGeolocationNative();
                        } else {
                            navigator.geolocation.getCurrentPosition(this.setPosition, null, {
                                enableHighAccuracy: true,
                                timeout: 15000,
                                maximumAge: 0
                            });
                        }
                    }
                }
            }
        });

        await this.fnGetParkingLog(this.state.lot);
    }

    fetchData = async (userId) => {
        await this.fetchVehiclesData(userId);
        await this.fetchSelectedPaymentMethod(userId);
        await this.fetchParkingData();
    }

    calculateLotsDistance = () => {
        const myLocation = this.state.myLocation;
        if (myLocation && this.state.lots.length > 0) {
            const lotsWithDistance = this.state.lots.map(lot => {
                const distance = calculateDistance (myLocation.lat, myLocation.lng, lot.center.lat, lot.center.lng);
                return {
                  ...lot,
                  distance,
                };
            });

            lotsWithDistance.sort((a, b) => a.distance - b.distance);
            this.setState({ lots: lotsWithDistance });
        }
    }

    selectVehicle = (plateNum) => {
        localStorage.setItem('selectedVehicle', plateNum);
        this.clearPaymentDataTimer();
        this.fetchParkingData();
    }

    addVehicle = async (newVehicle) => {
        if (this.props.currentUser) {
            try {
                const { affectedIds } = await postVehicle(newVehicle);
                if (affectedIds && affectedIds.length) {
                    this.selectVehicle(newVehicle.plateNum);
                    await this.fetchVehiclesData(this.state.userId);
                    await this.fetchSelectedPaymentMethod(this.state.userId);
                    NotificationManager.success(this.props.t('notification.success.postVehicle'));
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            localStorage.setItem('selectedVehicle', newVehicle.plateNum);
            let vehicles = this.state.vehicles;
            vehicles.push({ plateNum: newVehicle.plateNum });
            this.setState({ vehicles });
            this.clearPaymentDataTimer();
            this.fetchParkingData();
        }
    }

    deleteVehicle = async (plateNum) => {
        if (this.props.currentUser) {
            try {
                const forDeleteVehicle = this.state.vehicles.find(x => x.plateNum === plateNum);
                const { affectedIds } = await deleteVehicleFn(forDeleteVehicle.id);
                if (affectedIds && affectedIds.length) {
                    const newVehiclesList = this.state.vehicles.filter((e) => !affectedIds.includes(e.id));
                    this.setState({ vehicles: newVehiclesList });
                    const selectedVehicle = localStorage.getItem('selectedVehicle');
                    if (!newVehiclesList.find(x => x.plateNum === selectedVehicle)) {
                        this.clearPaymentDataTimer();
                        localStorage.removeItem('selectedVehicle');
                        if (newVehiclesList[0] && newVehiclesList[0].plateNum) {
                            localStorage.setItem('selectedVehicle', newVehiclesList[0].plateNum);
                        }
                        this.fetchParkingData();
                    }
                    NotificationManager.success(this.props.t('notification.success.deleteVehicle'));
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            const newVehiclesList = this.state.vehicles.filter(x => x.plateNum !== plateNum);
            this.setState({ vehicles: newVehiclesList });
            const selectedVehicle = localStorage.getItem('selectedVehicle');
            if (!newVehiclesList.find(x => x.plateNum === selectedVehicle)) {
                this.clearPaymentDataTimer();
                localStorage.removeItem('selectedVehicle');
                if (newVehiclesList[0] && newVehiclesList[0].plateNum) {
                    localStorage.setItem('selectedVehicle', newVehiclesList[0].plateNum);
                }
                this.fetchParkingData();
            }
            NotificationManager.success(this.props.t('notification.success.deleteVehicle'));
        }
        this.setState({ vehicleForDelete: null });
    };

    handleCancelDeleteVehicle = () => {
        this.setState({ vehicleForDelete: null });
    }

    handleSetDeleteVehicle = (plateNum) => {
        this.setState({ vehicleForDelete: plateNum });
    }

    startTimer = () => {
        this.getPaymentData();
        //additionally 5 secs, for backend operations
        const initTime = (65 - moment.moment().second()) * 1000;
        //timeout to synchronize getting the data on hh:mm:00 of real time
        this.getPaymentDataSynchronizeTimeout = setTimeout(() => {
            this.getPaymentData();
            this.getPaymentDataTimer = setInterval(this.getPaymentData, 60000);
        }, initTime);
    }

    clearPaymentDataTimer = () => {
        clearTimeout(this.getPaymentDataSynchronizeTimeout);
        clearInterval(this.getPaymentDataTimer);
    }

    getPaymentData = async () => {
        if (this.state.mounted) {
            let { lot, parkingLog } = this.state;

            if (parkingLog === '') {
                this.setState({ payment: '', totalTime: '', currentLotSearchFinished: true });
                this.clearPaymentDataTimer();
            }

            if (parkingLog && lot) {
                await getParkingLogById(parkingLog.parkingLogId, lot.id)
                    .then((response) => {
                        if (response) {
                            this.setState({ payment: response, totalTime: this.getTotalTime(response.entryDate, response.exitDate) });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.clearPaymentDataTimer();
                    })
            }
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    onPlaceChanged = async (lot) => {
        clearInterval(this.getPaymentDataTimer);
        this.setState({ lot: lot, open: false, parkingLog: null, currentLotSearchFinished: false });
        await this.fnGetParkingLog(lot);
    }

    fnGetParkingLog = async (lot) => {
        if (lot) {
            const plateNum = localStorage.getItem('selectedVehicle');
            getParkingLog(lot.id, plateNum).then((parkingLog) => {
                this.setState({ parkingLog: parkingLog, exitDate: parkingLog.exitDate });
                this.startTimer();
            })
        }
    }

    fnShowLots = () => {
        if (this.state.open === false) {
            this.calculateLotsDistance();
        }
        this.setState({ open: true })
    }

    selectBank = () => {
        this.props.history.push({
            pathname: 'bankSelection/bank'
        });
    }

    hanldeSlide = async () => {
        if (this.state.slideValue > 90) {
            switch (this.state.selectedPaymentMethod?.paymentType) {
                // case CARD:
                //     await this.goCardPayment();
                //     break;
                default:
                    await this.goLinkedPayment();
            }
        } else {
            this.setState({ slideValue: 0 })
        }
    }

    goLinkedPayment = async () => {
        const plateNum = localStorage.getItem('selectedVehicle');
        const bankId = this.state.selectedPaymentMethod?.bank.id;

        const exitTimeM = this.state.parkingLog.exitTimeM ? this.state.parkingLog.exitTimeM : 15;

        let paymentData = {
            redirect: `${window.location.origin}/paymentConfirm?exitTimeM=${exitTimeM}`,
            webhook: `${window.location.origin}/api/v1/Payments/confirmPayment`,
            lot: this.state.lot,
            payment: this.state.payment,
            userId: this.state.userId,
            bankId: bankId,
            plateNum: plateNum,
            provider: this.state.parkingLog.paymentProvider,
            paymentMethodPreferred: 'bank'
        }

        if (this.state.payment.amount > 0) {
            if (bankId) {
                initiatePayment(paymentData).then((kevin) => {
                    if (kevin) {
                        window.location.href = kevin.confirmLink;
                    }
                }).catch((error) => {
                    postPayment(paymentData, null, error.message).then(() => {
                        this.setState({ payment: null })
                        NotificationManager.error(error.message);
                    });
                })
            } else {
                this.props.history.push({
                    pathname: 'bankSelection/payment',
                    state: {
                        paymentData: paymentData
                    },
                });
            }
        } else {
            postPayment(paymentData, null, null).then(() => {
                NotificationManager.success('Free Exit');
                this.goBack();
            })
        }
    }

    goCardPayment = () => {
        const plateNum = localStorage.getItem('selectedVehicle');

        const exitTimeM = this.state.parkingLog.exitTimeM ? this.state.parkingLog.exitTimeM : 15;

        const paymentData = {
            redirect: `${window.location.origin}/paymentConfirm?exitTimeM=${exitTimeM}`,
            webhook: `${window.location.origin}/api/v1/Payments/confirmPayment`,
            lot: this.state.lot,
            payment: this.state.payment,
            userId: this.state.userId,
            bankId: null,
            plateNum: plateNum,
            provider: this.state.parkingLog.paymentProvider,
            paymentMethodPreferred: 'card'
        }

        initiatePayment(paymentData).then((kevin) => {
            if (kevin) {
                window.location.href = kevin.confirmLink;
            }
        }).catch((error) => {
            postPayment(paymentData, null, error.message).then(() => {
                this.setState({ payment: null })
                NotificationManager.error(error.message);
            });
        })
    };

    roleBack = (event, value) => {
        this.setState({ slideValue: value })
    }

    handleClose = () => {
        this.setState({ open: false })
    };

    handleSearchOptionSelection = (el) => {
        this.onPlaceChanged(el);
        this.setState({ openSearchOptions: false });
        this.addDataToSearchHistory(el);
    }

    addDataToSearchHistory = (el) => {
        let currentSearchLotsHistory = [...this.state.searchLotsHistory];
        const index = currentSearchLotsHistory.findIndex(item => item.id === el.id);

        if (index !== -1) {
            currentSearchLotsHistory.splice(index, 1);
            currentSearchLotsHistory.unshift(el);
        } else {
            currentSearchLotsHistory.unshift(el);
        }

        if (currentSearchLotsHistory.length > this.state.maxSearchHistoryLength) {
            currentSearchLotsHistory.pop();
        }

        this.setState({ searchLotsHistory: currentSearchLotsHistory }, () => {
            window.localStorage.setItem('searchLotsHistory', JSON.stringify(currentSearchLotsHistory));
        });
    }

    clearSearchHistory = () => {
        window.localStorage.removeItem('searchLotsHistory');
        this.setState({ searchLotsHistory: [] });
    }

    handleSetTypeOfParkings = (event, type) => {
        if (type !== null) {
            this.setState({ selectedTypeOfParkings: type });
        }
    }

    getTotalTime = (entryDate, exitDate) => {
        if (this.state.parkingLog || (entryDate && exitDate)) {
            const mins = moment.date(exitDate ?? this.state.exitDate).diff(moment.date(entryDate ?? this.state.parkingLog.entryDate), 'minutes');
            var days = mins / (60 * 24) | 0;
            var hours = (mins - (days * 60 * 24)) / 60 | 0;
            var minutes = mins % 60 | 0;

            return this.formatTime(days, hours, minutes);
        }
    }

    getDate = (date) => {
        return moment.date(date).format('YYYY-MM-DD').toString();
    }

    getTime = (date) => {
        const hours = moment.date(date).format('HH').toString();
        const minutes = moment.date(date).format('mm').toString();
        return this.formatTime('00', hours, minutes);
    }

    formatTime = (days, hours, mins) => {
        // string format - 00d 00h 00min
        let dateString = '';
        if (days !== '00' && days !== 0) {
            dateString = `${days} d `;
        }
        dateString += `${hours} h ${mins} min`;

        return dateString;
    }

    getVat = () => {
        if (this.state.payment && this.state.lot && this.state.payment.amount && this.state.lot.vat) {
            const result = (this.state.payment.amount * this.state.lot.vat) / (100 + this.state.lot.vat);
            return result.toFixed(2);
        } else {
            return 0.00;
        }
    }

    getWithoutVat = () => {
        if (this.state.payment && this.state.lot && this.state.payment.amount && this.state.lot.vat) {
            const result = this.state.payment.amount - this.getVat();
            return result.toFixed(2);
        } else {
            return 0.00;
        }
    }

    render() {
        const { t } = this.props;
        const { userId, vehicles, lots, lot, open, parkingLog, totalTime, slideValue, openSearchOptions, searchLotsHistory, searchLots, selectedTypeOfParkings, payment, vatAmount, withoutVatAmount, selectedPaymentMethod, vehicleForDelete, vehiclesLimit, currentLotSearchFinished } = this.state;
        return (
            <div>
                <AppBar color="inherit" elevation={0} sx={{ boxShadow: '0px 4px 4px 0px rgba(43, 68, 105, 0.10)' }}>
                    <Toolbar>
                        <Grid container justifyContent="center">
                            <Grid item sx={{ borderRadius: '0.5rem', backgroundColor: '#E8EEF6' }}>
                                <ToggleButtonGroup
                                    value={selectedTypeOfParkings}
                                    exclusive
                                    onChange={this.handleSetTypeOfParkings}
                                    sx={{
                                        color: '#3268B2',
                                        '& .MuiToggleButtonGroup-grouped': {
                                            margin: 0.5,
                                            border: 0,
                                            '&:not(:first-of-type)': {
                                                borderRadius: '0.5rem',
                                            },
                                            '&:first-of-type': {
                                                borderRadius: '0.5rem',
                                            }
                                        }
                                    }}
                                >
                                    <ToggleButton id='toggle-btn-active' value='active'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                            <circle cx="4" cy="4" r="4" fill="#67D889" />
                                        </svg>
                                        <div style={{ paddingRight: '10px' }}></div>
                                        {t('publicApp.Active')}
                                        <div style={{ paddingRight: '15px' }}></div>
                                    </ToggleButton>
                                    {/* for future
                                    <ToggleButton id='toggle-btn-history' value='history'>
                                    {t('publicApp.History')}
                                </ToggleButton> */}
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Toolbar sx={{ mb: 2 }} />

                <div className='public-form'>
                    <Box sx={{ pt: 1, pb: payment ? selectedPaymentMethod ? 25 : 22 : 9, position: 'relative', overflow: 'auto' }} >
                        {vehicles && vehicles.length === 0 && (
                            <Grid sx={{ mx: 3, mt: 1, mb: 2 }}>
                                <Grid><Typography variant='h4' className='h4-text-bold' gutterBottom>{t('publicApp.AddYourVehicle')}</Typography></Grid>
                                <Grid><Typography className='body2-text'>{t('publicApp.AddVehicleManual')}</Typography></Grid>
                            </Grid>
                        )}

                        <VehiclesCard t={t} vehicles={vehicles} selectVehicle={this.selectVehicle} addVehicle={this.addVehicle} deleteVehicle={this.handleSetDeleteVehicle} vehiclesLimit={vehiclesLimit} userId={userId} />

                        {vehicles && vehicles.length > 0 && (<>
                            <Card className='card-shadow' sx={{ p: 1, mt: 1, mx: 1, boxShadow: 0 }}>
                                <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1 }}>
                                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img alt='icon-parking' src={icon_parking} style={{ width: 48 }} />
                                    </Grid>
                                    <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h4" className='h4-text'>
                                            {t('general.ParkingLot')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {lots.length > 0 && (
                                    <Box sx={{ px: 1, width: '100%', textAlign: 'center' }}>
                                        <Divider />
                                        {lot && (
                                            <>
                                                <Grid sx={{ ml: 1, my: 1, textAlign: 'left' }}>
                                                    <Typography variant="h4" className='h4-text' gutterBottom>
                                                        {lot.name}
                                                    </Typography>
                                                    {lot.address && (<Typography variant="h4" className='body1-text'>
                                                        {lot.address}
                                                    </Typography>)}
                                                </Grid>
                                                <Divider />
                                            </>
                                        )}
                                        <Grid sx={{ mt: 2, mb: 1, mx: 2 }}>
                                            <Button
                                                id="btn-select-parking-lot"
                                                className="button-primary-outlined"
                                                onClick={() => this.fnShowLots()}
                                            >
                                                <Typography variant='string' className="body1-text blue-text double-letter-spacing" sx={{ py: 0.5 }}>
                                                    {lot && lot.id ? t('publicApp.NotThisParkingLot') : t('publicApp.FindParking')}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Box>
                                )}
                            </Card>

                            {payment ? (<Card className='card-shadow' sx={{ p: 1, mt: 1, mx: 1, boxShadow: 0 }}>
                                <Grid container alignItems="center" sx={{ pb: 0.75, pl: 1 }}>
                                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img alt='icon-payment' src={icon_eur} style={{ width: 48 }} />
                                    </Grid>
                                    <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h4" className='h4-text'>
                                            {t('publicApp.PaymentInformation')}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid sx={{ px: 1 }}>
                                    <Divider />
                                    <Grid container justifyContent="center" direction='row' sx={{ py: 1.5, px: 1 }}>
                                        <Grid item xs={4} alignItems="center" justifyContent="flex-start">
                                            <Typography variant="h4" className='h4-text'>
                                                {t('publicApp.Started')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} alignItems="center" justifyContent="center">
                                            <Typography variant="h4" className='h4-text' sx={{ textAlign: 'center' }}>
                                                {payment ? this.getDate(payment.entryDate) : '--'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} alignItems="center" justifyContent="flex-end">
                                            <Typography variant="h4" className='h4-text' sx={{ textAlign: 'right' }}>
                                                {payment ? this.getTime(payment.entryDate) : '00:00'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Divider />
                                    <Grid container>
                                        <Grid container justifyContent='space-between'>
                                            <Grid xs={6} item>
                                                <Typography variant="body2" className='body2-text' gutterBottom sx={{ pl: 1, pt: 0.75 }}>
                                                    {t('publicApp.TotalTime')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} justifyContent='flex-end'>
                                                <Typography variant="body2" className='body2-text' gutterBottom sx={{ pr: 1, pt: 0.75, textAlign: 'right' }}>
                                                    {totalTime}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent='space-between'>
                                            <Grid xs={6} item>
                                                <Typography variant="body2" className='body2-text' gutterBottom sx={{ pl: 1 }}>
                                                    {t('publicApp.Amount')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} justifyContent='flex-end'>
                                                <Typography variant="body2" className='body2-text' gutterBottom sx={{ pr: 1, textAlign: 'right' }}>
                                                    {withoutVatAmount + ' ' + lot?.currencyShort}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent='space-between'>
                                            <Grid xs={6} item>
                                                <Typography variant="body2" className='body2-text' gutterBottom sx={{ pl: 1 }}>
                                                    {t('publicApp.VAT') + ' ' + lot?.vat + '%'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} justifyContent='flex-end'>
                                                <Typography variant="body2" className='body2-text' gutterBottom sx={{ pr: 1, textAlign: 'right' }}>
                                                    {vatAmount + ' ' + lot?.currencyShort}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Divider sx={{ borderColor: '#3268B2' }} />
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Card sx={{ boxShadow: 0 }}>
                                                <Typography variant="h4" className='h4-text-bold' gutterBottom sx={{ pl: 1, pt: 1 }}>
                                                    {t('publicApp.TotalAmount')}
                                                </Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Card sx={{ boxShadow: 0, textAlign: 'right' }}>
                                                <Typography variant="h4" className='h4-text-bold' gutterBottom sx={{ pr: 1, pt: 1 }}>
                                                    {payment.amount.toFixed(2) + ' ' + lot?.currencyShort}
                                                </Typography>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>) : (
                                <>
                                    {lot && lot.id && (
                                        <Card className='card-shadow' sx={{ mx: 1, mt: 1 }}>
                                            <Grid sx={{ mx: 2, py: 5, textAlign: 'center' }}>
                                                {currentLotSearchFinished ? (
                                                    <>
                                                        <Grid sx={{ mb: 1 }}>
                                                            <NotFoundSearchIcon height='40px' />
                                                        </Grid>
                                                        <Typography variant='h4' className='h4-text'>{t('publicApp.NoParkingInformationFound')}</Typography>
                                                    </>
                                                ) : (
                                                    <CircularProgress color="primary" />
                                                )}
                                            </Grid>
                                        </Card>
                                    )}
                                </>
                            )}
                        </>)}
                    </Box>
                </div>

                {payment && payment.amount > 0 && <Container className='parking-footer-white' sx={{ touchAction: 'none', boxShadow: 12 }}>
                    <Grid container justifyContent="center" sx={{ pb: 11, pt: selectedPaymentMethod ? 1 : 4 }}>
                        {selectedPaymentMethod && (
                            <Grid
                                container
                                alignItems='center'
                                justifyContent='left'
                                onClick={this.selectBank}
                                sx={{ height: '30px', mb: 2, cursor: 'pointer' }}
                            >
                                {selectedPaymentMethod.paymentType && selectedPaymentMethod.paymentType === LINKED_BANK && (
                                    <Grid sx={{ ml: 1 }}>
                                        <FontAwesomeIcon icon={faLink} color='#2B4469' />
                                    </Grid>
                                )}
                                <>
                                {selectedPaymentMethod.paymentType === BANK ? (
                                    <>
                                        <Grid sx={{ mx: 1 }}>
                                            <BankImg item={selectedPaymentMethod.bank} tinyVariant={true} />
                                        </Grid>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </>
                                ) : (<></>)}
                                </>
                            </Grid>
                        )}
                        <Card className='parking-footer-button'>
                            <Grid container height="100%" alignItems="center">
                                <Grid item xs={2}></Grid>
                                <Grid item xs={7} sx={{ alignItems: 'center' }}>
                                    <Grid className='swipe-arrows'>
                                        <SwipeArrowRightIcon />
                                        <SwipeArrowRightIcon />
                                        <SwipeArrowRightIcon />
                                        <SwipeArrowRightIcon />
                                        <SwipeArrowRightIcon />
                                    </Grid>
                                    <Grid className='text-overlay'>
                                        <Typography variant="h4" className='h4-text-danger' >
                                            {t('publicApp.GoPayment')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4" className='h4-text-danger' >
                                        {payment && payment.amount && lot.currencyShort && `${payment.amount.toFixed(2)} ${lot.currencyShort}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <PaySlider
                        id='pay-slider'
                        onChange={this.roleBack}
                        onTouchEnd={this.hanldeSlide}
                        step={1}
                        disabled={!parkingLog}
                        value={slideValue}
                    />
                </Container>}

                <PublicFooter />

                <PopUpDrawer
                    isWarningMessage
                    header={t('publicApp.DeleteVehicle')}
                    text={t('confirm.Delete')}
                    showDrawer={vehicleForDelete !== null}
                    onClose={this.handleCancelDeleteVehicle}
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item xs={5} mt={2} mb={4}>
                            <Button
                                id="cancel-delete"
                                disableElevation
                                type="button"
                                variant="contained"
                                className="button-secondary-outlined"
                                onClick={this.handleCancelDeleteVehicle}
                                sx={{ height: '48px' }}
                            >
                                <Typography variant="string" className="button-text">
                                    {t('publicApp.CancelLogout')}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={5} mt={2} mb={4}>
                            <Button
                                id="confirm-delete"
                                disableElevation
                                type="button"
                                variant="contained"
                                color="secondary"
                                className="button-primary"
                                onClick={() => this.deleteVehicle(vehicleForDelete)}
                            >
                                <Typography variant="string" className="button-text">
                                    {t('general.Confirm')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </PopUpDrawer>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={this.handleClose}
                    className='public-dialog'
                    PaperProps={{
                        square: true
                    }}
                >
                    <motion.div
                    /* initial={{ y: -200 }}
                    animate={{ y: open ? 0 : -200 }}
                    transition={{ ease: 'easeOut', duration: 0.5 }}
                    style={{ position: 'fixed', top: 0, width: '100%', zIndex: 4 }} */
                    >
                        <Container className='map-header-blue' sx={{ padding: 1 }}>
                            <Grid mb={1}>
                                <Grid container alignItems="center">
                                    <Grid>
                                        <IconButton id='btn-go-back' style={{ paddingLeft: 0 }} onClick={this.handleClose}>
                                            <ChevronLeftIcon pl={0} stroke='#ffffff' />
                                        </IconButton>
                                    </Grid>
                                    <Grid pt={0.25}>
                                        <Typography variant="h3" className="secondary-title-text">
                                            {t('publicApp.Explore')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid width='100%' pl={1} pr={1}>
                                <Autocomplete
                                    id="input-search-lots"
                                    options={lots}
                                    fullWidth
                                    open={openSearchOptions}
                                    onOpen={() => this.setState({ openSearchOptions: true })}
                                    onClose={() => this.setState({ openSearchOptions: false })}
                                    disableCloseOnSelect
                                    className='autocomplete-search-lots'
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className="input-lots"
                                            placeholder={t('publicApp.FindParkingLot')}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={icon_search_active} alt="search-icon" style={{ width: '24px', height: '20px' }} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        {//for future
                                                        /*<IconButton id='show-my-location' onClick={() => {this.setState({open: false}); this.goCenter()}} sx={{padding: '0px !important'}}>
                                                            <ShowLocationIcon />
                                                        </IconButton> */}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <>
                                            <ListItem {...props} button onClick={() => { this.handleSearchOptionSelection(option) }}>
                                                <Grid container sx={{ my: option.address ? 0 : 0.5 }} justifyContent='space-between' alignItems='center'>
                                                    <Grid item xs={9.5}>
                                                        <Typography variant='body2' className="primary-body-text truncate-text">
                                                            {option.name}
                                                        </Typography>
                                                        {option.address && (<Typography variant="h4" className='body1-text'>
                                                            {option.address}
                                                        </Typography>)}
                                                    </Grid>
                                                    {option.distance && (
                                                        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'right' }}>
                                                            <Typography variant='h4' className='body1-text transparent-blue-text'>{option.distance + ' km'}</Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </ListItem>
                                            {option !== lots[lots.length - 1] && <Divider />}
                                        </>
                                    )}
                                    PaperComponent={(props) => { return (<Grid><Grid mb={1}></Grid><Paper mt={1} {...props} elevation={0} /></Grid>) }}
                                />
                            </Grid>
                        </Container>
                    </motion.div>
                    <motion.div>
                        <Grid m={2} mt={17}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                direction="row"
                                sx={{ marginTop: '30px', mb: 2, zIndex: 4 }}
                            >
                                <Typography variant='body2' className="primary-body-text">{t('publicApp.RecentSearches')}</Typography>
                                {searchLotsHistory.length > 0 && <Typography variant='body2' className="text-hyperlink" sx={{ cursor: 'pointer' }} onClick={this.clearSearchHistory}>{t('publicApp.ClearAll')}</Typography>}
                            </Grid>
                            <Paper elevation={0} className='paper-shadow'>
                                {searchLotsHistory.map((el, i) => (
                                    el.name.toLowerCase().includes(searchLots.toLowerCase()) || searchLots === '' ?
                                        <span key={i}>
                                            <ListItem button onClick={() => this.handleSearchOptionSelection(el)}>
                                                <Grid sx={{ my: el.address ? 0 : 0.5 }}>
                                                    <Typography variant='body2' className="primary-body-text">
                                                        {el.name}
                                                    </Typography>
                                                    {el.address && (<Typography variant="h4" className='body1-text'>
                                                        {el.address}
                                                    </Typography>)}
                                                </Grid>
                                            </ListItem>
                                            {i !== searchLotsHistory.length - 1 && <Divider />}
                                        </span> : null
                                ))}
                            </Paper>
                        </Grid>
                    </motion.div>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(withTranslation()(PublicParking));