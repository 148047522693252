import { ToggleButton as MuiToggleButton, styled } from '@mui/material';

const ToggleButton = styled(MuiToggleButton)(() => ({
  borderRadius: '0.5rem',
  padding: '2px',
  width: '100px',
  border: 'none',
  color: '#2B4469',
  fontFamily: 'Roboto-Semi-Bold',
  fontSize: '0.75rem',
  fontWeight: 500,

  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: '#3268B2'
  }
}));

export default ToggleButton;
