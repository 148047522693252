import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Grid, Typography, Card, Container } from '@mui/material';
import './Settings.scss';
import { updateSettingsLocale } from '../../../utils/SettingsService';
import i18n from '../../../utils/i18n';
import PublicTopbar from '../PublicTopbar';
import RadioButton from 'components/ui/buttons/RadioButton';

class AccountLanguages extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      currentLng: 'en-gb',
    };
  }

  languages = [
    {
      name: 'English',
      key: 'en-gb',
    },
    {
      name: 'Lietuvių',
      key: 'lt-lt',
    },
    {
      name: 'Polski',
      key: 'pl-pl',
    },
  ];

  componentDidMount = async () => {
    await this.props.checkAccount(this.props.history, false);
    if (this.props.currentUser) {
      const user = this.props.currentUser;
      this.setState({ user: user });
    }
    const currentLng = localStorage.getItem('currentLng');
    if (currentLng) {
      this.setState({ currentLng: currentLng });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleChange = async (event) => {
    this.setState({ currentLng: event.target.value });
    const lng = event.target.value;
    localStorage.setItem('currentLng', lng);
    const l = lng.toLowerCase();
    if (this.state.user) {
      await updateSettingsLocale(l, this.state.user.id);
    }
    i18n.changeLanguage(l);
  };

  clickLanguage = async (l) => {
    this.setState({ currentLng: l });
    localStorage.setItem('currentLng', l);
    if (this.state.user) {
      await updateSettingsLocale(l, this.state.user.id);
    }
    i18n.changeLanguage(l);
  };

  render() {
    const { currentLng } = this.state;
    const { t } = this.props;
    return (
      <div className="public-form">
        <PublicTopbar title={t('publicApp.Language')} enableShadow />
        <Container sx={{ paddingTop: 1 }} />
        <Typography variant='h4' className='h4-text-bold' sx={{ pt: 1.5, pl: 4, pb: 1 }}>{t('publicApp.ChooseLanguage')}</Typography>
        <Card className="card-shadow" sx={{ p: 1, py: 1, mx: 2 }}>
          <Grid sx={{ py: 1 }}>
            {this.languages.map((language) => (
              <Grid key={language.key} sx={{ p: 1, px: 2 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => this.clickLanguage(language.key)}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      className="h4-text-light"
                    >
                      {t(language.name)}
                    </Typography>
                  </Grid>
                  <Grid item justifyContent="center">
                    <RadioButton
                      value={language.key}
                      color="secondary"
                      onChange={() => {}}
                      checked={currentLng.toLowerCase() === language.key}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Card>
      </div>
    );
  }
}

export default withSnackbar(withTranslation()(AccountLanguages));
