import React, { Component } from 'react';
import './SpaceReassign.scss';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Topbar } from '../../../ui/topbar/Topbar';
import {
    getOwnerSpaces,
    spacesData,
    getSharedSpacesList
} from '../../../../utils/ParkingService';
import { getUser, getUsers } from '../../../../utils/UsersService';
import { postReassignSpaces, postDeleteSpaces } from '../../../../utils/SpacesService';
import SpacesOwnerList from '../../../spaces/owner-list/SpacesOwnerList';
import ExchangesList from '../../../exchanges/list/ExchangesList';
import BookedList from '../../../exchanges/booked-list/BookedList';
import UsersList from '../../list/UsersList';
import UserListItem from '../../list/item/UserListItem';
import { InputSearch } from 'components/ui/inputs/search/Search';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../../../constants/Api';
import {
    Grid,
    Checkbox,
    Typography,
    ListItem,
    ListItemIcon,
    Box,
    Divider,
    DialogActions,
    Button,
    Dialog,
    DialogContent,
    List,
    Fab,
    CircularProgress,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import InfiniteScroll from "react-infinite-scroll-component";
const searchAPIDebounced = AwesomeDebouncePromise(getUsers, Api.SEARCH_DEBOUNCE);

class SpaceReassign extends Component {

    state = {
        space: null,
        owners: [],
        usersList: [],
        spaceNames: null,
        spaces: null,
        sharings: null,
        bookings: null,
        spaceIds: [],
        selectedSpaces: null,
        selectedSharings: null,
        selectedBookings: null,
        checkedF: true,
        checkedFn: [],
        checkedList: [],
        showSlots: false,
        clicked: false,
        type: 'reassign',
        user: null,
        openedUsersDialog: false,
        spacesNotBooked: null,
        spacesToShow: null
    }

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, true);
        if (!this.props.location.state) {
            this.goBack();
        } else {
            const { space } = this.props.location.state;
            if (space) {
                const ownerId = space.ownerId;
                this.setState({ space });
                const spaces = await getOwnerSpaces(ownerId);
                this.setState({ spaces });




                // const checked = this.state.spaceNames.find(x => x.id === space.id);
                // await this.setState({ checked: [checked] });
                // this.setState({ checked: this.state.spaceNames });
                const resp = await getUser(ownerId)
                this.setState({ owners: [resp] })
                this.setState({ user: resp })
                let spaceDtos = null;
                if (spaces && spaces.length > 0 && spaces[0].bookingLevel === 2) {
                    const ids = spaces.map(x => x.id);
                    spaceDtos = await spacesData(ids);
                    if (spaceDtos) {
                        this.setState({ spaceNames: spaceDtos, checkedFn: spaceDtos, spacesToShow: spaceDtos });
                        await this.fetchSharedSpaces(ownerId);
                        await this.fetchSharedSpacesOccupied(ownerId, spaceDtos);
                        this.setSelected(spaceDtos);
                    }
                }
                else {
                    await this.fetchSharedSpaces(ownerId);
                    await this.fetchSharedSpacesOccupied(ownerId, spaceDtos);
                }


            } else {
                this.goBack();
            }
        }
    }

    setSelected = (selectedArray) => {
        const array = selectedArray.map(x => { return x.id });
        const selectedSpaces = this.state.spaces.filter(x => array.includes(x.id));
        const selectedSharings = this.state.sharings.filter(x => array.includes(x.parkingSpaceId));
        const selectedBookings = this.state.bookings.filter(x => array.includes(x.parkingSpaceId));
        this.setState({ selectedSpaces, selectedSharings, selectedBookings });
    }

    fetchSharedSpaces = async (ownerId) => {
        let sharedSpacesAll = [];
        if (ownerId) {
            const { items } = await getSharedSpacesList(
                ownerId,
                'pending',
                0,
                this.props.settings.User.UiUserDetailsListCountLimit.value
            );
            sharedSpacesAll = items;
        }
        this.setState({ sharings: sharedSpacesAll });
    };

    fetchSharedSpacesOccupied = async (ownerId, spaceDtos) => {
        let sharedSpacesOccupied = [];

        if (ownerId) {
            const { items } = await getSharedSpacesList(
                ownerId,
                'active',
                0,
                this.props.settings.User.UiUserDetailsListCountLimit.value
            );
            sharedSpacesOccupied = items;
        }

        this.setState({ bookings: sharedSpacesOccupied });

        if (spaceDtos) {
            if (sharedSpacesOccupied && sharedSpacesOccupied.length > 0) {
                const bookedIds = sharedSpacesOccupied.map(x => { return x.parkingSpaceId });
                const spacesNotBooked = this.state.spaceNames.filter(x => !bookedIds.includes(x.id));
                this.setState({ spacesNotBooked });
            } else {
                this.setState({ spacesNotBooked: this.state.spaceNames });
            }
        }
    };

    goBack = () => {
        this.props.history.goBack()
    }

    handleChange = async (event) => {
        console.log(event.target.checked);
        this.setState({ checkedF: event.target.checked });
        if (event.target.checked === true) {
            const checkedFn = this.state.spacesToShow;
            this.setSelected(checkedFn);
            this.setState({ checkedFn });

        } else {
            const checkedFn = [];
            this.setSelected(checkedFn);
            this.setState({ checkedFn });
        }

    }

    clickShowSlots = () => {
        this.setState({ showSlots: this.state.checkedFn.length > 0, checkedList: this.state.checkedFn });
    }

    handleToggle = (value) => {
        // console.log(value);
        const currentIndex = this.state.checkedList.indexOf(value);
        const newChecked = [...this.state.checkedList];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({ checkedList: newChecked });
    }

    slotsDialogCancel = () => {
        this.setState({ showSlots: false });
    }

    slotsDialogConfirm = async () => {
        const isChecked = this.state.checkedList.length > 0;
        const checkedFn = this.state.checkedList;
        this.setSelected(checkedFn);
        this.setState({ checkedFn: checkedFn, checkedF: isChecked, showSlots: false });
    }

    submit = async () => {
        this.setState({ clicked: true });
        const selectedIds = this.state.checkedFn.map(x => { return x.id });
        const type = this.state.type;
        if (type === 'reassign') {
            const userId = this.state.user.id;
            const data = await postReassignSpaces({ selectedIds, userId });
            if (data) {
                this.props.enqueueSnackbar(this.props.t('notification.success.reassigned'), {
                    variant: 'success',
                });
                this.props.history.goBack()
            }
            this.setState({ clicked: false });
        } else {
            const data = await postDeleteSpaces({ selectedIds });
            if (data) {
                this.props.enqueueSnackbar(this.props.t('notification.success.deleted'), {
                    variant: 'success',
                });
                this.props.history.goBack()
            }
            this.setState({ clicked: false });
        }
    }

    onTypeChange = async (event) => {
        this.setState({ type: event.target.value });
        if (event.target.value === 'delete') {
            this.setState({ submitTitle: this.props.t("general.Delete") });
            const checkedFn = this.state.spacesNotBooked ? this.state.spacesNotBooked : [];
            this.setSelected(checkedFn);
            this.setState({ checkedFn: checkedFn, spacesToShow: checkedFn, checkedF: checkedFn.length > 0 });
        } else {
            this.setState({ submitTitle: null });
            const checkedFn = this.state.spaceNames;
            this.setSelected(checkedFn);
            this.setState({ checkedFn: checkedFn, spacesToShow: checkedFn, checkedF: true });
        }
    }

    search = (e) => {
        this.fetchUsers(e.target.value)
    }

    changeShareWith = async () => {
        await this.fetchUsers()
        this.setState({ openedUsersDialog: true })
    }


    fetchUsers = async (query, offset, loadingMore) => {
        try {
            let hasMore = false;

            const resp = await searchAPIDebounced(query, offset)
            if (resp.nextOffset) hasMore = true

            if (!loadingMore) {
                this.setState(() => ({ usersList: [...resp.items], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore }))
            } else {
                this.setState(prevState => ({ usersList: [...new Set([...prevState.usersList, ...resp.items])], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore }))
            }

        }
        catch (e) {
            console.error(e)
        }
    }

    handleClose = () => {
        this.setState({ openedUsersDialog: false })
    }

    changeOwner = (user) => {
        this.setState({ user, openedUsersDialog: false })
    }

    render() {
        const { t, isAdmin, settings } = this.props;
        const {
            selectedSpaces,
            spaceNames,
            selectedSharings,
            selectedBookings,
            checkedF,
            showSlots,
            checkedList,
            submitTitle,
            type,
            owners,
            user,
            openedUsersDialog,
            checkedFn,
            spacesToShow
        } = this.state;
        const submitTitle_ = submitTitle ? submitTitle : t('general.Save');
        return (
            <div>
                <Topbar
                    title={t("spaces.Title")}
                    cbBack={this.goBack}
                    location={this.props.location}
                ></Topbar>

                <div className="SpaceReassignContainer">
                    <Box  >
                        <UsersList users={owners} />
                    </Box>

                    <Grid style={{ paddingLeft: '12px', paddingBottom: '10px' }}>
                        <RadioGroup
                            value={type}
                            onChange={this.onTypeChange}
                        >
                            <FormControlLabel value="reassign" control={<Radio color="primary" style={{ maxHeight: '15px', paddingBottom: '10px'  }} />} label={t("general.Reassign")} />
                            <Grid  paddingBottom= '10px' item xs={12} style={{ listStyle: 'none' }}>
                                {isAdmin && user && type === "reassign" && <UserListItem
                                    user={user}
                                    className="UserCard"
                                    onChange={isAdmin ? this.changeShareWith : false}
                                />
                                }
                            </Grid>
                            <FormControlLabel disabled={!this.state.spacesNotBooked || (this.state.spacesNotBooked.length == 0)} value="delete" control={<Radio color="primary" style={{ maxHeight: '15px' }} />} label={t("general.Delete")} />
                        </RadioGroup>

                    </Grid>
                    <Grid container>
                        {spaceNames && spaceNames.length > 0 && <Grid item xs={12} className="SpaceReassignContainerChek">
                            <Checkbox className="CheckBoxComponent"
                                checked={checkedF}
                                onChange={this.handleChange} color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Typography onClick={this.clickShowSlots} style={{ cursor: 'pointer' }}>
                                {checkedFn && checkedFn.length === spaceNames.length || !checkedF ? t("exchanges.AllSlots") : t("exchanges.SeveralSlots")}
                            </Typography>
                        </Grid>}
                    </Grid>

                    {selectedSpaces && selectedSpaces.length > 0 && (
                        <SpacesOwnerList
                            spaces={selectedSpaces}
                            spaceNames={spaceNames}
                            hideOwnerIcon={true}
                            listLimit={settings.User.UiUserDetailsListCountLimit.value}
                        />
                    )}

                    {selectedSharings && selectedSharings.length > 0 && (
                        <ExchangesList
                            exchanges={selectedSharings}
                            isAdmin={isAdmin}
                            needLoad={false}
                            title={t('exchanges.Sharing')}
                            canEdit={true}
                            parkingSpaces={selectedSpaces}
                            // parkingLots={parkingLots}
                            spaceNames={spaceNames}
                        />
                    )}

                    {selectedBookings && selectedBookings.length > 0 && (
                        <BookedList
                            bookedSpaces={selectedBookings}
                            title={t('exchanges.sharedSpacesOccupied')}
                            parkingSpaces={selectedSpaces}
                            // parkingLots={parkingLots}
                            spaceNames={spaceNames}
                        />
                    )}
                    {selectedSpaces && <Grid
                        spacing={1}
                        container
                        direction="row"
                        className="SpaceReassignContainerBtn"
                        justifyContent="center"
                        alignItems="center"
                    >

                        <Grid item xs={10}>
                            <Fab disabled={this.state.clicked || !selectedSpaces || selectedSpaces.length < 1 || (type === 'reassign' && owners[0] === user)}
                                className="full-width secondary-btn" variant="extended"
                                onClick={this.submit}
                                aria-label="Save">
                                {this.state.clicked ?
                                    <Box position="relative" display="inline-flex">
                                        <CircularProgress size={30} color={'secondary'} />
                                    </Box> :
                                    <Box fontSize={14} textAlign="center">
                                        {submitTitle_}
                                        {/* {t('general.Save')} */}
                                    </Box>
                                }
                            </Fab>
                        </Grid>
                    </Grid>}
                </div>
                <Dialog open={showSlots}>
                    <div>
                        <DialogContent>
                            <List>
                                {spacesToShow && spacesToShow.map((el, i) => (
                                    <div key={i}>
                                        <ListItem >
                                            <ListItemIcon >
                                                <Checkbox
                                                    edge="start"
                                                    checked={checkedList.indexOf(el) !== -1}
                                                    tabIndex={-1}
                                                    // disableRipple
                                                    onChange={(value) => this.handleToggle(el)}
                                                    color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </ListItemIcon>
                                            <Box className='ExchangeSpaceBlock'>
                                                <div className='ExchangeSpaceTitle'>{el.name}</div></Box>
                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={this.slotsDialogCancel} color="primary">
                                {t('general.Cancel')}
                            </Button>
                            <Button onClick={this.slotsDialogConfirm} color="primary">
                                {t('general.Confirm')}
                            </Button>
                        </DialogActions>

                    </div>
                </Dialog>
                {isAdmin && <Dialog fullScreen open={openedUsersDialog} onClose={this.handleClose} >
                    <Topbar
                        title={t("users.Title")}
                        closeDialog={this.handleClose}
                        cbCreate={this.createUser}
                    >

                    </Topbar>
                    <Grid item container paddingTop='50px' justifyContent="center" alignItems="center">
                        <InputSearch onChange={this.search} placeholder={t("general.Search")} />
                    </Grid>
                    {this.state.usersList &&
                        <div id="UsersDialogContainer" className="UsersDialogContainer">
                            <List >
                                <InfiniteScroll
                                    dataLength={this.state.usersList.length}
                                    hasMore={(this.state.hasMore)}
                                    height={600}
                                    next={() => this.fetchUsers(this.state.query, this.state.nextOffset, true)}
                                    loader={
                                        <p>{t('general.loading')}...</p>
                                    }
                                >
                                    {this.state.usersList.length > 0 &&
                                        this.state.usersList.map((e, i) => (
                                            <UserListItem
                                                user={e}
                                                key={(e.id + i)}
                                                onSelect={this.changeOwner}
                                            />
                                        ))}
                                </InfiniteScroll>
                            </List>
                        </div>
                    }
                </Dialog>}
            </div>
        )
    }
}

export default withSnackbar(withTranslation()(SpaceReassign))