import React from 'react';
import { AppBar, Container, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/publicApp/icon_chevron_left.svg';
import { useHistory } from 'react-router-dom';
import './index.scss';

const PublicTopbar = (props) => {
  const { title, elevation = 0, enableShadow = false } = props;
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Grid>
      <AppBar color="inherit" elevation={elevation} position="fixed" sx={{ boxShadow: enableShadow ? '0px 4px 4px 0px rgba(43, 68, 105, 0.10)' : 'none' }}>
        <Grid mt={1} mr={2} ml={2} pb={1}>
          <Grid container alignItems="center">
            <Grid mr={0.75}>
              <IconButton id='go-back-arrow' onClick={handleGoBack}>
                <ChevronLeftIcon stroke='#2B4469' />
              </IconButton>
            </Grid>
            <Grid pt={0.25}>
              <Typography variant="h3" className="title-text">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
      <Container sx={{ marginTop: '65px' }}></Container>
    </Grid>
  );
};

export default PublicTopbar;
