import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Box, Card, Grid, Typography, Divider, Button, FormControl, Select, MenuItem, Checkbox, Link } from '@mui/material';
import { kevin_banks, authenticate } from 'utils/KevinService';
import PublicTopbar from 'components/public/PublicTopbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronUp,
    faChevronDown,
    faLink,
} from '@fortawesome/free-solid-svg-icons';
import BankImg from 'components/public/providers/kevin/BankImg';
import Flags from '../../../../assets/icons/publicApp/flags.svg';
import './BankSelection.scss';
import { initiatePayment } from 'utils/KevinService';
import { postPayment } from 'utils/PaymentsService';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as VisaLogo } from '../../../../assets/icons/publicApp/logo_visa.svg';
import { ReactComponent as MasterCardLogo } from '../../../../assets/icons/publicApp/logo_mastercard.svg';
import { CARD, LINKED_BANK, BANK } from 'constants/PaymentTypes';

class BankSelection extends Component {
    constructor() {
        super();
        this.state = {
            mounted: true,
            allBanks: [],
            banks: [],
            linkedBanks: null,
            selectedPaymentMethod: null,
            countries: [],
            countryCode: 'LT',
            agreeKevinPolicy: true,
            action: null,
            paymentData: null,
        };
        this.timer = {};
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.setState({ mounted: false });
    };

    componentDidMount = async () => {
        await this.props.checkAccount(this.props.history, false);
        this.startTimer();
        const currentLng = localStorage.getItem('currentLng');
        let countryCode_ = localStorage.getItem('countryCode');
        if (!countryCode_) {
            countryCode_ = currentLng ? currentLng.slice(-2).toUpperCase() : 'LT';
        }

        const kevinTokens = JSON.parse(localStorage.getItem('kevinTokens')) ?? [];

        const selectedPaymentMethod = JSON.parse(localStorage.getItem('selectedPaymentMethod'));
        console.log(selectedPaymentMethod)
        this.setState({ linkedBanks: kevinTokens, selectedPaymentMethod: selectedPaymentMethod });

        kevin_banks().then((allBanks) => {
            if (allBanks) {
                this.setState({ allBanks });

                const countries = allBanks
                    .filter((x) => x.hidden === 'false')
                    .map((x) => {
                        return x.countryCode;
                    })
                    .filter((value, index, array) => array.indexOf(value) === index);
                this.setState({ countries });
                const countryCode = countries.includes(countryCode_)
                    ? countryCode_
                    : 'LT';
                this.setState({ countryCode });
                const banks = allBanks.filter(
                    (x) => x.countryCode === countryCode && x.hidden === 'false'
                );
                this.setState({ banks });
            }
        });

        const action = this.props.match.params.action;
        this.setState({ action });
        if (action === 'payment') {
            if (this.props.location.state) {
                const paymentData = this.props.location.state.paymentData;
                if (paymentData) {
                    this.setState({ paymentData });
                }
            }
        }
    };

    startTimer = () => {
        this.timer = setInterval(this.goBack, 60000);
    };

    goBack = () => {
        this.props.history.goBack();
    };

    countryChange = async (event) => {
        const countryCode = event.target.value;
        localStorage.setItem('countryCode', countryCode);
        this.setState({ countryCode });
        const banks = this.state.allBanks.filter(
            (x) => x.countryCode === countryCode && x.hidden === 'false'
        );
        this.setState({ banks });
    };

    goAction = (paymentType, bank) => {
        switch (this.state.action) {
            case 'payment': {
                if (this.state.paymentData) {
                    let paymentData = this.state.paymentData;
                    if (paymentType === CARD) {
                        paymentData.bankId = null;
                        paymentData.paymentMethodPreferred = 'card';
                    } else if (paymentType === BANK) {
                        paymentData.bankId = bank.id;
                        paymentData.paymentMethodPreferred = 'bank';
                    }
                    initiatePayment(paymentData)
                        .then((kevin) => {
                            if (kevin) {
                                window.location.href = kevin.confirmLink;
                            }
                        })
                        .catch((error) => {
                            postPayment(paymentData, null, error.message).then(() => {
                                this.setState({ payment: null });
                                NotificationManager.error(error.message);
                            });
                        });
                }
                break;
            }
            case 'bank': {
                this.props.history.push({
                    pathname: '/publicParking',
                });
                this.setPaymentMethod(paymentType, bank);
                break;
            }
            case 'account': {
                authenticate(bank.id).then((kevin) => {
                    if (kevin) {
                        window.location.href = kevin.authorizationLink;
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
                break;
            }
            default: {
                this.goBack();
                break;
            }
        }
    };

    setPaymentMethod = (paymentType, method) => {
        const selectedPaymentMethod = { paymentType, 'bank': method };
        localStorage.setItem('selectedPaymentMethod', JSON.stringify(selectedPaymentMethod));
    }

    handleLinkedBankSelection = (linkedBank) => {
        this.goAction(LINKED_BANK, linkedBank);
    }

    handleCardSelection = () => {
        this.goAction(CARD);
    }

    handleBankSelection = (bank) => {
        this.goAction(BANK, bank);
    }

    render() {
        const { t } = this.props;
        const { banks, linkedBanks, selectedPaymentMethod, countries, countryCode, agreeKevinPolicy, action } =
            this.state;

        return (
            <>
                <PublicTopbar enableShadow title={action === 'account' ? t('publicApp.BankAccountLinking') : t('publicApp.Payment')} />

                <div className="content">
                    <Grid container>
                        <Grid item xs={11} sx={{ mt: 2, mx: 4 }}>
                            <Typography variant="h4" className="h4-text" sx={{ mb: 1 }}>
                                {t('publicApp.ChoosePaymentMethod')}
                            </Typography>
                            <Typography variant="body2" className="body1-text" sx={{ mb: 2 }}>
                                {action === 'account' ? t('publicApp.LinkingContinueChooseData') : t('publicApp.PaymentContinueChooseData')}
                            </Typography>
                        </Grid>
                    </Grid>
                    {action === 'bank' && linkedBanks && linkedBanks.length > 0 && (
                        <Card className="card-shadow" sx={{ p: 1, m: 1, boxShadow: 0 }}>
                            <Box sx={{ m: 1, mb: 0.5 }}>
                                <Typography
                                    variant="h4"
                                    className="h4-text"
                                    sx={{ ml: 1, pb: 2 }}
                                >
                                    {t('publicApp.LinkedBank')}
                                </Typography>
                                {linkedBanks && linkedBanks.map((bank) => {
                                    return (
                                        <Button
                                            id={`btn-linked-bank-${bank.name}`}
                                            className={selectedPaymentMethod && selectedPaymentMethod.paymentType === LINKED_BANK && selectedPaymentMethod.bank && selectedPaymentMethod.bank.id === bank.id ? "bank-button-selected" : "bank-button"}
                                            onClick={() => this.handleLinkedBankSelection(bank)}
                                            sx={{ boxShadow: 0, mb: 1.5 }}
                                        >
                                            <Grid key={bank}>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    {bank && <BankImg item={bank} />}
                                                    <Grid sx={{ position: 'absolute', right: '1rem' }}>
                                                        <FontAwesomeIcon icon={faLink} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    );
                                })}
                            </Box>
                        </Card>
                    )}
{/*                     {(action === 'payment' || action === 'bank') && (
                        <Card className="card-shadow" sx={{ p: 1, m: 1, boxShadow: 0 }}>
                            <Box sx={{ m: 1, mb: 2 }}>
                                <Typography
                                    variant="h4"
                                    className="h4-text"
                                    sx={{ ml: 1, pb: 2 }}
                                >
                                    {t('publicApp.CardPayment')}
                                </Typography>
                                <Button
                                    id='btn-pay-with-card'
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    type="button"
                                    onClick={() => this.handleCardSelection()}
                                    className={selectedPaymentMethod && selectedPaymentMethod.paymentType === CARD ? "bank-button-selected" : "bank-button"}
                                >
                                    <Grid sx={{ pt: 1, pr: 1 }}>
                                        <VisaLogo />
                                    </Grid>
                                    <Grid sx={{ pt: 1, pr: 1 }}>
                                        <MasterCardLogo />
                                    </Grid>
                                    <Typography
                                        variant="h4"
                                        className="h4-text-blue"
                                        sx={{ pr: 4 }}
                                    >
                                        {t('publicApp.PayWithCard')}
                                    </Typography>
                                </Button>
                            </Box>
                        </Card>
                    )} */}
                    <Card
                        className="card-shadow"
                        sx={{ pt: 1, px: 1, pb: 2, m: 1, boxShadow: 0 }}
                    >
                        <Grid sx={{ m: 1, mb: 2 }}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="h4" className="h4-text" sx={{ ml: 1 }}>
                                    {action === 'account' ? t('publicApp.BankCountry') + ':' : t('publicApp.BankPayment')}
                                </Typography>
                                {banks.length > 0 && countries.length > 0 && (
                                    <Box direction="row" className="select-country">
                                        <FormControl fullWidth size="small">
                                            <Select
                                                id="select-country"
                                                value={countryCode}
                                                label="Country"
                                                variant="standard"
                                                onChange={(e) => this.countryChange(e)}
                                                renderValue={(selected) => (
                                                    <Grid
                                                        id={`selected-${selected}`}
                                                        container
                                                        alignItems="center"
                                                    >
                                                        <CountryFlag countryCode={selected} />
                                                        <Typography
                                                            variant="h6"
                                                            className="h6-text"
                                                            sx={{ ml: 1 }}
                                                        >
                                                            {t(`publicApp.${selected}`)}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                IconComponent={(props) => (
                                                    <FontAwesomeIcon
                                                        icon={
                                                            props.className.includes('Open')
                                                                ? faChevronUp
                                                                : faChevronDown
                                                        }
                                                        color="#455D80"
                                                    />
                                                )}
                                            >
                                                {countries.map((value) => {
                                                    return (
                                                        <MenuItem id={value} value={value} key={value}>
                                                            <CountryFlag countryCode={value} />
                                                            <Typography
                                                                variant="h6"
                                                                className="h6-text"
                                                                sx={{ ml: 1 }}
                                                            >
                                                                {t(`publicApp.${value}`)}
                                                            </Typography>
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                        {agreeKevinPolicy &&
                            banks.length > 0 &&
                            banks.map((bank) => (
                                <Box sx={{ m: 1, mb: 1.5 }} key={bank.id}>
                                    <Button
                                        id={`btn-bank-${bank.name}`}
                                        disableElevation
                                        fullWidth
                                        size="large"
                                        type="button"
                                        onClick={() => this.handleBankSelection(bank)}
                                        className={selectedPaymentMethod && selectedPaymentMethod.paymentType === BANK && selectedPaymentMethod.bank && selectedPaymentMethod.bank.id === bank.id ? "bank-button-selected" : "bank-button"}
                                    >
                                        <BankImg item={bank} />
                                    </Button>
                                </Box>
                            ))}
                        <Grid sx={{ mt: 1, mb: 2, mr: 1 }}>
                            <Grid container direction="row">
                                <Grid
                                    xs={1.5}
                                    item
                                    alignItems="flex-start"
                                    justifyContent="right"
                                >
                                    <Checkbox
                                        label="agree-kevin-policy"
                                        checked={agreeKevinPolicy}
                                        onChange={() =>
                                            this.setState({ agreeKevinPolicy: !agreeKevinPolicy })
                                        }
                                        sx={{
                                            color: '#DCE1E9',
                                            '&.Mui-checked': {
                                                color: '#3268B2 !important',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={10} sx={{ mt: 1, ml: 1 }}>
                                    <KevinAgreementTextComponent />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </>
        );
    }
}

const CountryFlag = ({ countryCode }) => (
    <svg className="img-flag">
        <use href={`${Flags}#${countryCode}`} />
    </svg>
);

const KevinAgreementTextComponent = () => {
    const currentLng = localStorage.getItem('currentLng').toLocaleLowerCase();
    const kevinTermsURI = 'https://www.kevin.eu/docs/terms-and-conditions/';
    const kevinPolicyURI = 'https://www.kevin.eu/docs/privacy-policy/';

    switch (currentLng) {
        case 'lt-lt':
            return (
                <Typography variant="body2" className="body1-text">
                    Naudodamiesi UAB „Kevin EU” teikiama mokėjimo inicijavimo paslauga
                    patvirtinate, kad perskaitėte ir sutinkate su{' '}
                    <Link
                        href={kevinTermsURI}
                        target="_blank"
                        className="body2-hypertext"
                    >
                        Taisyklėmis ir sąlygomis
                    </Link>{' '}
                    bei{' '}
                    <Link
                        href={kevinPolicyURI}
                        target="_blank"
                        className="body2-hypertext"
                    >
                        Privatumo politika
                    </Link>
                    .
                </Typography>
            );
        case 'pl-pl':
            return (
                <Typography variant="body2" className="body1-text">
                    Korzystając z usługi inicjowania płatności świadczonej przez Kevin EU,
                    UAB, potwierdzasz, że zapoznałeś się i akceptujesz{' '}
                    <Link
                        href={kevinTermsURI}
                        target="_blank"
                        className="body2-hypertext"
                    >
                        Regulamin
                    </Link>{' '}
                    oraz{' '}
                    <Link
                        href={kevinPolicyURI}
                        target="_blank"
                        className="body2-hypertext"
                    >
                        Politykę prywatności
                    </Link>
                    .
                </Typography>
            );
        default:
            return (
                <Typography variant="body2" className="body1-text">
                    By using the payment initiation service provided by Kevin EU, UAB, you
                    confirm that you have read and agree with the{' '}
                    <Link
                        href={kevinTermsURI}
                        target="_blank"
                        className="body2-hypertext"
                    >
                        Terms and conditions
                    </Link>{' '}
                    and{' '}
                    <Link
                        href={kevinPolicyURI}
                        target="_blank"
                        className="body2-hypertext"
                    >
                        Privacy policy
                    </Link>
                    .
                </Typography>
            );
    }
};

export default withSnackbar(withTranslation()(BankSelection));
