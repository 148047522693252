import { axiosA } from './AuthService';
import MomentUtils from '@date-io/moment';

const moment = new MomentUtils({ locale: 'lt' });

const postSilencePeriod = async (period) => {
  const body = {
    // id: period.id || '',
    from: period.from,
    to: period.to,
    userId: period.userId || '',
  };
  const { data } = await axiosA.post(`/SilencePeriods`, body);
  return data;
};

const getSilencePeriods = async (userId, dateFrom, limit) => {
  let query = '?1';
  if (userId) {
    query += `&UserId=${userId}`;
  }

  if (dateFrom) {
    let today = moment
      .date(dateFrom)
      .utc(0)
      .startOf('day')
      .format('YYYY-MM-DDT00:00:00.000')
      .toString();

    query += `&From=${today}`;

    if (limit) {
      query += `&Limit=${limit}`;
    } else {
      query += `&Limit=10`;
    }
  }

  const { data } = await axiosA.get(`/SilencePeriods${query}`);
  return data;
};

const deleteSilencePeriod = async (periodId) => {
  const { data } = await axiosA.delete(`/SilencePeriods/${periodId}`);
  return data;
};

const checkForBookedSpaces = async (userId, dateFrom, dateTo) => {
  let query = '?1';
  if (userId) {
    query += `&UserId=${userId}`;
  }

  if (dateFrom) {
    query += `&From=${dateFrom}`;
  }

  if (dateTo) {
    query += `&To=${dateTo}`;
  }

  try {
    const { data } = await axiosA.get(`/SilencePeriods/check${query}`);
    return data;
  } catch (e) {
    throw new Error(e);
  }

}

export { postSilencePeriod, getSilencePeriods, deleteSilencePeriod, checkForBookedSpaces };
