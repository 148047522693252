import React, { useState, useEffect } from 'react';
import './Carousel.scss';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/react-flicking/dist/flicking-inline.css';
import { Grid, Typography } from '@mui/material';
import { Perspective } from '@egjs/flicking-plugins';
import CustomFade from './CustomFade';
import { getImage } from 'utils/ImagesService';
import defaultParkingImage1 from '../../assets/images/default_parking_lot_bg1.svg';
import defaultParkingImage2 from '../../assets/images/default_parking_lot_bg2.svg';

const Carousel = ({ parkingLots, onSelect }) => {
  const [loadedParkingLotsData, setLoadedParkingLotsData] = useState([]);
  const defaultImageList = [defaultParkingImage1, defaultParkingImage2];
  let defaultImageIndex = Math.floor(Math.random() * defaultImageList.length);

  const fadePlugin = new CustomFade('', 1.85);
  const perspectivePlugin = new Perspective({
    scale: 0.2,
    rotate: 0,
    perspective: 0,
  });
  const plugins = [fadePlugin, perspectivePlugin];

  const flickingOptions = {
    defaultIndex: 1,
    deceleration: 0.001,
    threshold: 0,
    iOSEdgeSwipeThreshold: 0,
    circular: true,
    easing: (x) => 1 - Math.pow(1 - x, 3),
    preventClickOnDrag: true,
    renderOnlyVisible: true,
    autoResize: true,
    visualviewport: 3,
    moveType: ['strict', { count: 3 }],
    plugins: plugins,
  };

  useEffect(() => {
    if (parkingLots !== null && parkingLots.length !== 0) {
      loadImagesForParkingLots();
    }
  }, [parkingLots]);

  const loadImagesForParkingLots = async () => {
    try {
      const loadedImages = await Promise.all(
        parkingLots.map(async (lot) => {
          const imageName = lot.id + '.jpg';
          const parkingLotImage = await getImage(imageName);
          let image = parkingLotImage;

          if (parkingLotImage === null) {
            image = defaultImageList[defaultImageIndex];
            defaultImageIndex =
            defaultImageIndex + 1 > defaultImageList.length - 1
              ? 0
              : defaultImageIndex + 1;
          }

          return {
            id: lot.id,
            name: lot.name,
            image: image,
          };
        })
      );

      const newParkingLotList = loadedImages.length < 6 ? [...loadedImages, ...loadedImages] : loadedImages;

      setLoadedParkingLotsData(newParkingLotList);
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };

  const handleSelection = (item) => {
    if (onSelect) {
      const selectedLot = parkingLots.find((x) => x.id === item.id);
      onSelect(selectedLot);
    }
  };

  return (
    <div>
      {/* Options for Flicking: https://naver.github.io/egjs-flicking/Options */}
      {loadedParkingLotsData.length > 0 && (
        <Flicking {...flickingOptions} className="carousel">
          {loadedParkingLotsData.map((item, index) => (
            <Grid
              key={index}
              className="image-container"
              onClick={() => handleSelection(item)}
            >
              <img src={item.image} alt={item.name} />
              <Typography variant="body2" className="territory-text">
                {item.name}
              </Typography>
            </Grid>
          ))}
        </Flicking>
      )}
    </div>
  );
};

export default Carousel;
