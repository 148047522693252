import PropTypes from 'prop-types';
import {Redirect, useHistory} from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import config from 'config';
import React, { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
   // const navigate = useHistory();

    useEffect(() => {
        if (isLoggedIn) {
            <Redirect
                to={{
                    pathname: "/spaces"
                    //  state: { from: props.location }
                }}
            />
        }
    }, [isLoggedIn]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
