import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';

const formatLicenseNumber = (license) => {
  if (license.length === 6) {
    //if standard LT license plate
    const firstLicensePart = license.slice(0, 3);
    const secondLicensePart = license.slice(3);
    if (
      firstLicensePart.match(/^[A-Za-z]+$/) &&
      secondLicensePart.match(/^[0-9]+$/)
    ) {
      return firstLicensePart + ' ' + secondLicensePart;
    }
  }

  return license;
};

const LicensePlate = ({
  licenseNumber,
  selected = true,
  simplified = false,
  onClick,
  ...rest
}) => {
  const finalLicenseNumber = licenseNumber
    ? formatLicenseNumber(licenseNumber)
    : '---';

  return (
    <Box {...rest}>
      <Card
        className={selected ? 'number-plate' : 'number-plate-inactive'}
        sx={{
          boxShadow: 0,
          my: 1,
          minWidth: '160px',
        }}
      >
        <Grid container direction="row">
          {!simplified && (
            <Grid
              item
              sx={{
                width: '16px',
                flex: '0 0 auto',
                backgroundColor: selected ? '#3268B2' : '#C1D2E8',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="4" cy="4" r="4" fill="#fff" />
              </svg>
            </Grid>
          )}
          <Grid sx={{ px: 1, flex: '1 1 auto', backgroundColor: '#FBFCFE' }}>
            <Typography
              variant="body2"
              className={
                selected || simplified
                  ? 'parking-plate-num'
                  : 'parking-plate-num-inactive'
              }
              onClick={onClick}
            >
              {finalLicenseNumber}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default LicensePlate;
